import axios from 'axios';

const getDescriptionText = async (nameApp, langApp) => {

    if (!nameApp || !langApp) {
        console.error("App name or language is not set.");
        return null;
    }

    console.log(nameApp, ' ---- ' ,langApp);

    const apiKey = 'sk-proj-PwR3BI7mjJeq3P1Oyn3mT3BlbkFJgBNWbeQJu3F1mdAazsr6';
    try {
        const res = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                model: 'gpt-4o-mini',
                messages: [
                    {
                        role: 'user',
                        content: `Generate description app, with app name ${nameApp} and language must be ${langApp}, and text must be in the  casino theme. Text description must be 5 - 8 sentence, add emoji to text. Return response in json format object, without additional quotes`
                    }
                ],
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                },
            }
        );
        const result = JSON.parse(res.data.choices[0].message.content);
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export default getDescriptionText;