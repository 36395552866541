import {React, useState, useEffect, StatePool, axios} from "../../../imports/imports-packege";
import {ActionTitle, SelectList, PopoverInfo, Input, Button} from "../../../imports/imports-components";
import { UploadFileIcon } from "../../../imports/imports-images";
import { useData } from "../../Context/ContextApp";
import AppDesignStyle from "./AppDesignStyle.module.scss";
import { LanguagePwaList, ListOptionLanguageEnVersion } from "../../SelectOption/SelectOption";
import TranslateBuilderText from '../../TranslatePagesElement/translate-constructor.json';
import '../../../assets/style/AppStyle.css';
import CopyDesign from "./CopyDesign";

const ParseLink = StatePool.createState(undefined);
const PreviewIcon = StatePool.createState('');
const PreviewScreen = StatePool.createState([null, null, null, null]);

const AppDesign = () => {
    const { data, updateData } = useData();
    const [translateCode, setTranslateCode] = useState('en');
    const queryParams = new URLSearchParams(window.location.search);
    const translateQueryParameter = queryParams.get('ln');
    const handleTranslate = (code_translate) => {
        setTranslateCode(code_translate)
    }

    useEffect(() => {
        handleTranslate(translateQueryParameter)
    }, [translateQueryParameter])

    const [appName, setAppName] = useState('');
    const [previewUrl, setPreviewUrl] = StatePool.useState(PreviewIcon);
    const handlerAppName = (query_appName) => {
        setAppName(query_appName);
    };
    useEffect((app_name)=>{
        if (app_name) {
            handlerAppName(app_name);
        }
        updateData({
            ...data,
            landName: appName
        })
    }, [appName]);

    useEffect(() => {
        handlerAppName(data.landName)
    }, [data.landName]);


    const handleIconUrl = (event) => {
        const file = event.target.files[0];
        handleFileChange({ target: { files: [file] } });
        if (file) {
            const previewIcon = new FileReader();
            previewIcon.onload = () => {
                setPreviewUrl(previewIcon.result);
            }

            previewIcon.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }

    const [iconBaseCode, setIconBaseCode] = useState('');
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                const fileObject = new File([file], 'icon_resize.png', { type: file.type });
                setIconBaseCode(base64String);
                updateData({
                    ...data,
                    iconUrl: base64String,
                    iconFile:  fileObject
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const [previewScreens, setPreviewScreens] = StatePool.useState(PreviewScreen);
    const handleScreen_1 = (event) => {
        const file = event.target.files[0];
        handleScreen_1Change({ target: { files: [file] } });
        if (file) {
            const previewScreen = new FileReader();
            previewScreen.onload = () => {
                const updatedScreens = [...previewScreens];
                updatedScreens[0] = previewScreen.result;
                setPreviewScreens(updatedScreens);
            }
            previewScreen.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }

    const handleScreen_2 = (event) => {
        const file = event.target.files[0];
        handleScreen_2Change({ target: { files: [file] } });
        if (file) {
            const previewScreen = new FileReader();
            previewScreen.onload = () => {
                const updatedScreens = [...previewScreens];
                updatedScreens[1] = previewScreen.result;
                setPreviewScreens(updatedScreens);
            }
            previewScreen.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }
    const handleScreen_3 = (event) => {
        const file = event.target.files[0];
        handleScreen_3Change({ target: { files: [file] } });
        if (file) {
            const previewScreen = new FileReader();
            previewScreen.onload = () => {
                const updatedScreens = [...previewScreens];
                updatedScreens[2] = previewScreen.result;
                setPreviewScreens(updatedScreens);
            }
            previewScreen.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }
    const handleScreen_4 = (event) => {
        const file = event.target.files[0];
        handleScreen_4Change({ target: { files: [file] } });
        if (file) {
            const previewScreen = new FileReader();
            previewScreen.onload = () => {
                const updatedScreens = [...previewScreens];
                updatedScreens[3] = previewScreen.result;
                setPreviewScreens(updatedScreens);
            }
            previewScreen.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }

    const handleScreen_1Change = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                const fileObject = new File([file], file.name, { type: file.type });
                updateData({
                    ...data,
                    screen_1: fileObject,
                    screenshotImages: { ...data.screenshotImages, screen_1: imageUrl },
                });
            };
            reader.readAsDataURL(file);
        }
    }
    const handleScreen_2Change = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                const fileObject = new File([file], file.name , { type: file.type });
                updateData({
                    ...data,
                    screen_2: fileObject,
                    screenshotImages: { ...data.screenshotImages, screen_2: imageUrl },
                });
            };
            reader.readAsDataURL(file);
        }
    }
    const handleScreen_3Change = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                const fileObject = new File([file], file.name , { type: file.type });
                updateData({
                    ...data,
                    screen_3: fileObject,
                    screenshotImages: { ...data.screenshotImages, screen_3: imageUrl },
                });
            };
            reader.readAsDataURL(file);
        }
    }
    const handleScreen_4Change = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                const fileObject = new File([file], file.name , { type: file.type });
                updateData({
                    ...data,
                    screen_4: fileObject,
                    screenshotImages: { ...data.screenshotImages, screen_4: imageUrl },
                });
            };
            reader.readAsDataURL(file);
        }
    }
    const [selectedTranslate, setSelectedTranslate] = useState(data.langCode || '');
    useEffect(() => {
        updateData({ ...data, langCode: selectedTranslate });
    }, [selectedTranslate]);
    const handleSelectTranslate = (translate) => {
        setSelectedTranslate(translate);
    }
    useEffect(() => {
        setSelectedTranslate(data.langCode);
    }, [data.langCode]);

    function HideInputLoadImages () {
        let iconBlock = document.getElementById('icon_block');
        let screenBlock = document.getElementById('screen_block');
        iconBlock.classList.add(`${AppDesignStyle.Section__hide}`);
        screenBlock.classList.add(`${AppDesignStyle.Section__hide}`);
    }

    const [LinkParse, setLinkParse] = StatePool.useState(ParseLink);
    const handleLinkParse = (link) => setLinkParse(link);
    const keitaroId = data.keitaroId;
    const sendLinkParse = async () => {
        let url = 'https://pwa-builder.com/api/parse-googleplay?link_parse=';
        // let url = 'http://localhost:8181/api/parse-googleplay?link_parse=';
        // let url = 'https://test.pwa-builder.com/api/parse-googleplay?link_parse=';
        if (!LinkParse) {
            alert('Додайте посилання на гру');
            return;
        }
        await axios.get(`${url}${LinkParse}&keitaro_id=${keitaroId}`)
            .then((response) => {
                if (response.data.error) {
                    alert('Ой, схоже саме цей додаток не вийде поцупити з Play Market, \b спробуй, будь ласка, інший');
                    return false;
                }
                let iconLink = response.data.icon;
                let screensLink = response.data.screen;
                updateData({ ...data, iconUrl: iconLink, screenshotImages: screensLink, parseContents: true});
                HideInputLoadImages();
            });
    }

    useEffect(() => {
        if (LinkParse !== undefined) {
            HideInputLoadImages()
        }
    }, [LinkParse]);


    return (
        <div className={AppDesignStyle.TabContent}>
            <div className={AppDesignStyle.Section}>
                <ActionTitle
                    actionText={TranslateBuilderText[translateCode]['app-page']['name-app']}
                />
                <Input
                    className={AppDesignStyle.InputField}
                    inputValue={handlerAppName}
                    placeholder="App name"
                    valueSave={appName}/>
            </div>
            <div className={`${AppDesignStyle.Section} ${AppDesignStyle.SelectList}`}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['app-page']['language-app']}/>
                <SelectList
                    optionList={translateCode === 'ua' ? LanguagePwaList : ListOptionLanguageEnVersion}
                    getSelectValue={handleSelectTranslate}
                    value={selectedTranslate}
                />
            </div>
            <div className={AppDesignStyle.Section}>
                <h2 className={AppDesignStyle.CustomTitle}>{TranslateBuilderText[translateCode]['app-page']['parse-app']}</h2>
                <PopoverInfo
                    TitleText={TranslateBuilderText[translateCode]['app-page']['tooltip-title-parse']}
                    DescriptionText={TranslateBuilderText[translateCode]['app-page']['tooltip-content-parse']}
                />
                <Input
                    inputValue={handleLinkParse}
                    valueSave={LinkParse}
                    placeholder={TranslateBuilderText[translateCode]['app-page']['placeholder-copy-link']}/>

                <div onClick={sendLinkParse}>
                    <Button ButtonText={TranslateBuilderText[translateCode]['app-page']['button-parse']}/>
                </div>
            </div>

            <div className={AppDesignStyle.Section}>
                <CopyDesign/>
            </div>

            <div className={`${AppDesignStyle.Section}`} id="icon_block">
                <ActionTitle actionText={TranslateBuilderText[translateCode]['app-page']['icon']}/>

                <div className={AppDesignStyle.SelectIconBlock}>
                    <div className={AppDesignStyle.DownloadIconApp}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleIconUrl}
                            className={AppDesignStyle.DownloadIconApp_IconButtonDowload}
                        />
                        <img src={UploadFileIcon} className={AppDesignStyle.DownloadIconApp_Icon}
                             alt="Upload File Icon"/>
                        <p className={AppDesignStyle.DownloadIconApp_TextBlock}>
                            <span
                                className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>{TranslateBuilderText[translateCode]['app-page']['size']}</span>
                            <span className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>512x512px</span>
                        </p>
                    </div>
                    {previewUrl && (
                        <img src={previewUrl} className={AppDesignStyle.PreviewImage} alt="Preview"/>
                    )}
                </div>
            </div>

            <div className={`${AppDesignStyle.Section}`} id="screen_block">
                <ActionTitle actionText={TranslateBuilderText[translateCode]['app-page']['screenshots']}/>
                <div className={AppDesignStyle.ScreenshotList}>
                    {[1, 2, 3, 4].map(index => {
                        const handleScreen = (event, screenIndex) => {
                            switch (screenIndex) {
                                case 1:
                                    handleScreen_1(event);
                                    break;
                                case 2:
                                    handleScreen_2(event);
                                    break;
                                case 3:
                                    handleScreen_3(event);
                                    break;
                                case 4:
                                    handleScreen_4(event);
                                    break;
                                default:
                                    break;
                            }
                        };

                        return (
                            <div className={AppDesignStyle.ContainerItem} key={index}>
                                <div className={`${AppDesignStyle.DownloadIconApp} ${AppDesignStyle.ScreenItem}`}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) => handleScreen(event, index)}
                                        className={AppDesignStyle.DownloadMultiScreen_MultiImageButtonDowload}
                                    />
                                    <img src={UploadFileIcon} className={AppDesignStyle.DownloadIconApp_Icon}
                                         alt="Upload File Icon"/>
                                    <p className={AppDesignStyle.DownloadIconApp_TextBlock}>
                                        <span
                                            className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>{TranslateBuilderText[translateCode]['app-page']['size']}</span>
                                        <span
                                            className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>720x1280px</span>
                                    </p>
                                </div>
                                {previewScreens[index - 1] && (
                                    <img key={index} src={previewScreens[index - 1]}
                                         className={AppDesignStyle.PreviewImage} alt="Preview"/>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default AppDesign;
