import React, {useCallback, useEffect, useState, ContentEditable, StatePool} from "../../../imports/imports-packege";
import AppReviewStyle from "./AppReviewStyle.module.scss";
import {ActionTitle, PopoverInfo, SelectList} from "../../../imports/imports-components";
import {CountReviews, ListOptionLanguageEnVersion, ListOptionLanguageReview} from "../../SelectOption/SelectOption";
import {TemplateUserIcon, UnnamedIcon} from "../../../imports/imports-images";
import {useData} from "../../Context/ContextApp";
import TranslateBuilderText from '../../TranslatePagesElement/translate-constructor.json';
import FemaleUsers from './ReviewsUserInfo/female_icon.json';
import MaleUsers from './ReviewsUserInfo/male_icon.json';
import ReviewsInfo from './ReviewsUserInfo/reviews.json';
import Input from "../../UI/Input/Input";
import AppDesignStyle from "../AppDesign/AppDesignStyle.module.scss";


const AppReview = () => {
    let { data, updateData } = useData();

    const [translateCode, setTranslateCode] = useState('en');
    const queryParams = new URLSearchParams(window.location.search);
    const translateQueryParameter = queryParams.get('ln');
    const handleTranslate = (code_translate) => {
        setTranslateCode(code_translate);
    };

    useEffect(() => {
        handleTranslate(translateQueryParameter);
    }, [translateQueryParameter]);

    useEffect(() => {
    }, [data.templateReview]);

    const [languageTemplateReview, setLanguageTemplateReview] = useState('');
    const [checkBoxStatus, setCheckBoxStatus] = useState(data.templateReviewCheck);
    const [saveLanguage, setSaveLanguage] = useState('');

    const handleSaveLanguage = (language) => setSaveLanguage(language);

    useEffect(() => {
        setCheckBoxStatus(data.templateReviewCheck);
    }, [data.templateReviewCheck]);

    const handleLanguageReview = (translate) => {
        setLanguageTemplateReview(translate);
        updateData({ ...data, langReviewSelect: translate });
    };


    useEffect(() => {
        if (data.langReviewSelect) {
            setLanguageTemplateReview(data.langReviewSelect);
        }
    }, [data.langReviewSelect]);

    useEffect(() => {
        updateData({ ...data, templateReviewCheck: checkBoxStatus });
    }, [checkBoxStatus]);

    /****************************/
    const [reviewError, setErrorReview] = useState('');
    const handleReviewError = (error) => setErrorReview(error);
    useEffect(() => {

    }, [reviewError]);

    const [countComments, setCountComments] = useState(data.countComments);
    const handleCountReview = (count) => {
        if (count === '') {
            handleReviewError('');
            setCountComments(count);
        } else if (!/^\d*$/.test(count)) {
            handleReviewError('Введене значення має бути числом.');
        } else if (parseInt(count, 10) > 30) {
            handleReviewError('Значення не може перевищувати 30.');
        } else {
            handleReviewError('');
            setCountComments(count);
        }
    };

    useEffect(()=>{
        if (countComments !== '') {
            updateData({ ...data, countComments: parseInt(countComments, 10) });
        }
    }, [countComments]);

    const [statusIconUsersReview, setStatusIconUsersReview] = useState(data.statusIconReview);
    const handleIconReview = (event) => {
        let eventStatus = event.target.checked;
        setStatusIconUsersReview(eventStatus);
    }
    useEffect(() => {
        updateData({ ...data, statusIconReview: statusIconUsersReview });
    }, [data.statusIconReview]);

    const [statusTextUserReview, setStatusTextUserReview] = useState(data.statusIconTextReview);
    const handleTextReview = (event) => {
        let eventStatus = event.target.checked;
        updateData({ ...data, statusIconTextReview: eventStatus });
        setStatusTextUserReview(eventStatus);
    };
    useEffect(() => {
        updateData({ ...data, statusIconTextReview: statusTextUserReview });
    }, [data.statusIconTextReview]);

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    function GetRandomUser (file, amountUser) {
        let arrayUser = [];

        for (let i = 0; i < amountUser; i++) {
            let randomNumber = getRandomInt(96);
            arrayUser.push(file.nicknames[randomNumber]);
        }
        return arrayUser;
    }

    function GetRandomMessage (amountMessage, categories, lang) {
        let fileMessage = ReviewsInfo;
        let arrayMessage = [];
        let contentListMessage = fileMessage[categories][lang];

        for (let i = 0; i < amountMessage; i++) {
            let randomNumber = getRandomInt(74);
            arrayMessage.push(contentListMessage[randomNumber]);
        }

        return arrayMessage;
    }

    function MergeUsersName (femaleName, maleName) {
        let fullListUserName = [];
        for (let item of femaleName) {
            fullListUserName.push(item)
        }
        for (let item of maleName) {
            fullListUserName.push(item)
        }
        return fullListUserName;
    }

    function RandomColor () {
        return `rgba(${getRandomInt(255)}, ${getRandomInt(255)}, ${getRandomInt(255)}, .9)`;
    }

    function RandomIconUser (iconChecked, symbolChecked) {
        if (iconChecked && symbolChecked) {
            return 'random';
        }

        if (iconChecked && !symbolChecked) {
            return 'icon';
        }

        if (!iconChecked && symbolChecked) {
            return 'symbol'
        }

        if (!iconChecked && !symbolChecked) {
            return 'icon'
        }
    }

    function decreaseDate(dayMinus) {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - dayMinus);

        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear() % 100;

        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        return `${day}.${month}.${year}`;
    }

    function CombineNameUserMessage(femaleName, maleName, message, iconChecked, symbolSymbol) {
        let listUserReviews = [];
        let fullListNameUser = MergeUsersName(femaleName, maleName);
        let iconType = RandomIconUser(iconChecked, symbolSymbol);
        let count = 0;

        if (data.templateReview.length > 0 && message === '') {
            for (let j = 0; j < data.templateReview.length; j++) {
                listUserReviews[j] = {
                    name: data.templateReview[j]['name'],
                    icon: data.templateReview[j]['icon'],
                    rating: data.templateReview[j]['rating'],
                    comment: data.templateReview[j]['comment'],
                    date: data.templateReview[j]['date'],
                    developer: data.templateReview[j]['developer'],
                    backgroundColor: RandomColor(),
                    iconType: iconType === 'random' ? getRandomInt(2) : iconType
                };
            }
        } else if (data.templateReview.length > 0 && message !== '') {
            if (data.countComments !== data.templateReview.length) {
                for (let q = 0; q < fullListNameUser.length; q++) {
                    listUserReviews[q] = {
                        name: fullListNameUser[q].nickname,
                        icon: fullListNameUser[q].iconUrl || fullListNameUser[q].icon,
                        rating: message[q].rating,
                        comment: message[q].comment,
                        date: decreaseDate(count),
                        developer: message[q].developer_response,
                        backgroundColor: RandomColor(),
                        iconType: iconType === 'random' ? getRandomInt(2) : iconType
                    };
                    count++;
                }
            } else {
                for (let k = 0; k < data.templateReview.length; k++) {
                    listUserReviews[k] = {
                        name: data.templateReview[k]['name'],
                        icon: data.templateReview[k]['icon'],
                        rating: data.templateReview[k]['rating'],
                        date: data.templateReview[k]['date'],
                        comment: message[k].comment,
                        developer: message[k].developer_response,
                        backgroundColor: RandomColor(),
                        iconType: iconType === 'random' ? getRandomInt(2) : iconType
                    };
                }
            }
        } else {
            for (let i = 0; i < fullListNameUser.length; i++) {
                listUserReviews[i] = {
                    name: fullListNameUser[i].nickname,
                    icon: fullListNameUser[i].iconUrl || fullListNameUser[i].icon,
                    rating: message[i].rating,
                    date: decreaseDate(count),
                    comment: message[i].comment,
                    developer: message[i].developer_response,
                    backgroundColor: RandomColor(),
                    iconType: iconType === 'random' ? getRandomInt(2) : iconType
                };
                count++;
            }
        }

        return listUserReviews;
    }

    const shuffle = <T>(array: T[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    function PreviewReviews(amountComments, languageComments, categories, imageIcon, symbolIcon) {
        let femaleUsersAmount = getRandomInt(amountComments);
        let maleUsersAmount = amountComments - femaleUsersAmount;
        let femaleListUsers = GetRandomUser(FemaleUsers, femaleUsersAmount);
        let maleListUsers = GetRandomUser(MaleUsers, maleUsersAmount);
        let messageList = GetRandomMessage(amountComments, categories, languageComments);
        let combineReviewList = [];

        if (data.templateReview.length > 0 && saveLanguage !== languageComments) {
            handleSaveLanguage(languageComments);
            combineReviewList = CombineNameUserMessage(femaleListUsers, maleListUsers, messageList, imageIcon, symbolIcon);
        } else if (data.templateReview.length !== amountComments) {
            combineReviewList = CombineNameUserMessage(femaleListUsers, maleListUsers, messageList, imageIcon, symbolIcon);
        } else if (data.templateReview.length > 0) {
            combineReviewList = CombineNameUserMessage('', '', '', imageIcon, symbolIcon);
        } else {
            combineReviewList = CombineNameUserMessage(femaleListUsers, maleListUsers, messageList, imageIcon, symbolIcon);
        }

        return shuffle(combineReviewList);
    }

    const [reviewGenerateStatus, setReviewGenerateStatus] = useState(false);
    const handleReviewGenerateStatus = (status) => setReviewGenerateStatus(status);

    const [listReviewRender, setListReviewRender] = useState([]);
    const handleListReviewRender = (reviews) => {
        let sortReview = reviews.sort((a, b) => {
            if (!a.date || !b.date) return 0;
            const [dayA, monthA, yearA] = a.date.split('.');
            const [dayB, monthB, yearB] = b.date.split('.');

            const dateA = new Date(`20${yearA}-${monthA}-${dayA}`);
            const dateB = new Date(`20${yearB}-${monthB}-${dayB}`);

            return dateB - dateA ;
        });
        setListReviewRender(sortReview);
        updateData({ ...data, templateReview : sortReview });
    }

    useEffect(() => {
        if (data.countComments && data.langReviewSelect) {
            if (
                data.templateReview &&
                data.templateReview.length === data.countComments &&
                statusIconUsersReview === data.templateReview[0].iconType
            ) {
                handleListReviewRender(data.templateReview);
                handleReviewGenerateStatus(true);
            } else {
                const newReviews = PreviewReviews(
                    data.countComments,
                    data.langReviewSelect,
                    'casino',
                    statusIconUsersReview,
                    statusTextUserReview
                );
                handleListReviewRender(newReviews);
                handleReviewGenerateStatus(true);
            }
        }
    }, [data.countComments, data.langReviewSelect, statusIconUsersReview, statusTextUserReview]);


    const handleEditReview = (event, index, field) => {
        const newValue = event.target.textContent;
        const updatedReviews = [...listReviewRender];
        updatedReviews[index][field] = newValue;
        setListReviewRender(updatedReviews);

        updateData(prevData => ({
            ...prevData,
            templateReview: updatedReviews
        }));
    };

    useEffect(() => {
        if (data.templateReview && data.templateReview.length > 0) {
            handleListReviewRender(data.templateReview);
        }
    }, []);

    return (
        <div className={AppReviewStyle.TabContent}>
            <div className={AppReviewStyle.CountReview}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['reviews-page']['reviews-number']} />
                <div className={AppReviewStyle.CountReview_SelectList}>
                    <Input
                        className={AppDesignStyle.InputField}
                        inputValue={handleCountReview}
                        placeholder="Amount comment"
                        valueSave={countComments}/>
                    {reviewError && <p style={{ color: 'red' }}>{reviewError}</p>}
                </div>
            </div>
            <div className={AppReviewStyle.TemplateReviews}>
                <div className={AppReviewStyle.TemplateReviews}>
                    <PopoverInfo
                        TitleText={TranslateBuilderText[translateCode]['reviews-page']['language-tooltip']}
                        DescriptionText={TranslateBuilderText[translateCode]['reviews-page']['language-tooltip-content']} />
                    <SelectList
                        optionList={translateCode === 'ua' ? ListOptionLanguageReview : ListOptionLanguageEnVersion}
                        getSelectValue={handleLanguageReview}
                        value={languageTemplateReview}
                    />
                </div>
                <div className={AppReviewStyle.TemplateReviews}>
                    <div className={AppReviewStyle.TitleReview}>
                        <PopoverInfo
                            TitleText=""
                            DescriptionText={TranslateBuilderText[translateCode]['reviews-page']['type-icon-tooltip-content']}/>
                        <ActionTitle actionText={TranslateBuilderText[translateCode]['reviews-page']['type-icon-user']}
                                     style={10}/>
                    </div>

                    <div className={AppReviewStyle.CheckerIconContainer}>
                        <div className={AppReviewStyle.CheckerIconContainer__checker}>
                            <div className={AppReviewStyle.CheckBoxCustomContainer}>
                                <input
                                    type="checkbox"
                                    checked={statusIconUsersReview}
                                    onChange={(e) => handleIconReview(e)}
                                    className={AppReviewStyle.CheckBoxCustomContainer__input}
                                    id="IconImageReviewer"
                                />
                                <label htmlFor="IconImageReviewer"></label>
                            </div>

                            <img src={TemplateUserIcon} alt="Template User Icon"/>
                        </div>
                        <div className={AppReviewStyle.CheckerIconContainer__checker}>
                            <div className={AppReviewStyle.CheckBoxCustomContainer}>
                                <input
                                    type="checkbox"
                                    checked={statusTextUserReview}
                                    onChange={(e) => handleTextReview(e)}
                                    className={AppReviewStyle.CheckBoxCustomContainer__input}
                                    id="IconTextReviewer"
                                />
                                <label htmlFor="IconTextReviewer"></label>
                            </div>
                            <img src={UnnamedIcon} alt="Unnamed Icon"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={AppReviewStyle.ReviewsBlock}>
                {
                    reviewGenerateStatus === true &&
                    (
                        listReviewRender.map((element, index) => (
                        <div key={index} className={AppReviewStyle.PreviewReview}>
                            <div className={AppReviewStyle.PreviewReview__InfoUser}>
                                <p className={AppReviewStyle.PreviewReview__Container_Logo} style={{backgroundColor: element['backgroundColor']}}>
                                    <img
                                        src={element['icon']}
                                        alt=""
                                        className={AppReviewStyle.PreviewReview__Logo}
                                        style={{display: (element['iconType'] === 0) ? 'none' :
                                                (element['iconType'] === 1) ? 'inline-block' :
                                                    (element['iconType'] === 'icon') ? 'inline-block' : 'none'}}/>
                                    <span
                                        className={AppReviewStyle.PreviewReview__Symbol}
                                        style={{display: (element['iconType'] === 0) ? 'flex' :
                                                (element['iconType'] === 1) ? 'none' :
                                                    (element['iconType'] === 'icon') ? 'none' : 'flex'}}>
                                        {element['name'].charAt(0).toUpperCase()}
                                    </span>
                                </p>
                                <p
                                    className={AppReviewStyle.PreviewReview__NameDeveloper}
                                    suppressContentEditableWarning
                                    contentEditable
                                    onBlur={(e) => handleEditReview(e, index, 'name')}
                                >
                                    {element['name']}
                                </p>
                            </div>
                            <div className={AppReviewStyle.PreviewReview__ReviewTextContainer}>
                                <p
                                    className={AppReviewStyle.PreviewReview__TextReview}
                                    suppressContentEditableWarning
                                    contentEditable
                                    onBlur={(e) => handleEditReview(e, index, 'comment')}
                                >
                                    {element['comment']}
                                </p>
                                <p
                                    className={AppReviewStyle.PreviewReview__TextResponseDeveloper}
                                    suppressContentEditableWarning
                                    contentEditable
                                    onBlur={(e) => handleEditReview(e, index, 'developer')}
                                >
                                    {element['developer']}
                                </p>
                            </div>
                        </div>)
                    ))
                }
            </div>
        </div>
    );
};

export default AppReview;
